import React from 'react'
import './App.scss'
import {Universe} from "./components/Universe/Universe"

function App() {
    return (
      <Universe/>
    )
}

export default App
