import {CardType} from "../common/types"
import power2016 from 'src/img/videos/wrestThumb/2016-power competition on Shrove Tuesday.jpg'
import video42 from 'src/img/videos/wrestThumb/video-42.jpg'
import video41 from 'src/img/videos/wrestThumb/video-41.jpg'
import video37 from 'src/img/videos/wrestThumb/video-37.jpg'
import video36 from 'src/img/videos/wrestThumb/video-36.jpg'
import video35 from 'src/img/videos/wrestThumb/video-35.jpg'
import video33 from 'src/img/videos/wrestThumb/video-33.jpg'
import video29 from 'src/img/videos/wrestThumb/video-29.jpg'
import video27 from 'src/img/videos/wrestThumb/video-27.jpg'
import video26 from 'src/img/videos/wrestThumb/video-26.jpg'
import video24 from 'src/img/videos/wrestThumb/video-24.jpg'
import video23 from 'src/img/videos/wrestThumb/video-23.jpg'
import video19 from 'src/img/videos/wrestThumb/video-19.jpg'
import video16 from 'src/img/videos/wrestThumb/video-16.jpg'
import video12 from 'src/img/videos/wrestThumb/video-12.jpg'
import video8 from 'src/img/videos/wrestThumb/video-8.jpg'
import video6 from 'src/img/videos/wrestThumb/video-6.jpg'
import video4 from 'src/img/videos/wrestThumb/video-4.jpg'
import video3 from 'src/img/videos/wrestThumb/video-3-ww.jpg'
import video2 from 'src/img/videos/wrestThumb/video-2.jpg'
import video1 from 'src/img/videos/wrestThumb/video-1.jpg'
import video9 from 'src/img/videos/mmaThumb/video-9.jpg'
import video17 from 'src/img/videos/mmaThumb/video-17.jpg'
import video18 from 'src/img/videos/mmaThumb/video-18.jpg'
import video20 from 'src/img/videos/mmaThumb/video-20.jpg'
import video21 from 'src/img/videos/mmaThumb/video-21.jpg'
import video13 from 'src/img/videos/boksThumb/video-13.jpg'
import alexElena from 'src/img/videos/mixWrestThumb/2011-alex-elena.jpg'
import video3wm from 'src/img/videos/mixWrestThumb/video-3-wm.jpg'
import video5 from 'src/img/videos/mixWrestThumb/video-5.jpg'
import video7 from 'src/img/videos/mixWrestThumb/video-7.jpg'
import video10 from 'src/img/videos/mixWrestThumb/video-10.jpg'
import video14 from 'src/img/videos/mixWrestThumb/video-14.jpg'
import video15 from 'src/img/videos/mixWrestThumb/video-15.jpg'
import video22 from 'src/img/videos/mixWrestThumb/video-22.jpg'
import video25 from 'src/img/videos/mixWrestThumb/video-25.jpg'
import video28 from 'src/img/videos/mixWrestThumb/video-28.jpg'
import video30 from 'src/img/videos/mixWrestThumb/video-30.jpg'
import video31_1 from 'src/img/videos/mixWrestThumb/video-31-1.jpg'
import video31_2 from 'src/img/videos/mixWrestThumb/video-31-2.jpg'
import video32 from 'src/img/videos/mixWrestThumb/video-32.jpg'
import video34 from 'src/img/videos/mixWrestThumb/video-34.jpg'
import video38 from 'src/img/videos/mixWrestThumb/video-38.jpg'
import video39 from 'src/img/videos/mixWrestThumb/video-39.jpg'
import video40 from 'src/img/videos/mixWrestThumb/video-40.jpg'
import nc45 from 'src/img/videos/mixWrestThumb/NC45_Braivs_Alyouna.jpg'
import nc46 from 'src/img/videos/wrestThumb/nc46.jpg'
import nc47 from 'src/img/videos/wrestThumb/nc47cover_small.jpg'
import nc48 from 'src/img/videos/wrestThumb/nc48.jpg'
import nc49 from 'src/img/videos/mixWrestThumb/nc49_preview.jpg'
import nc50 from 'src/img/videos/mixWrestThumb/NC50.jpg'
import nc51 from 'src/img/videos/wrestThumb/nc51.jpg'

export const cardsWrestling: Array<CardType> = [
    {
        id: 'NC51',
        title: 'Kara, Darya, Tais. 08.05.2012',
        imgRef: nc51,
    },
    {
        id: 'NC48',
        title: 'Competitions Spring 2017',
        imgRef: nc48,
    },
    {
        id: 'NC47',
        title: 'Women\'s strength competiton at Maslenitsa 2017',
        imgRef: nc47,
    },
    {
        id: 'NC46',
        title: 'Alena vs Kurmandi 2017',
        imgRef: nc46,
    },
    {
        id: 'NC44',
        title: 'The Maslenitsa Festival, 2015-2017\nPins-and-Submissions Wrestling',
        imgRef: power2016,
    },
    {
        id: 'NC42',
        title: 'Submission Grappling\nTournament. Part 2. 2014',
        imgRef: video42,
    },
    {
        id: 'NC41',
        title: 'Submission Grappling\nTournament. Part 1. 2014',
        imgRef: video41,
    },
    {
        id: 'NC37',
        title: 'Tournament between beginners\nPart 2. Final fights. 2014',
        imgRef: video37,
    },
    {
        id: 'NC36',
        title: 'Tournament between beginners\nPart 1. Preliminary fights. 2014',
        imgRef: video36,
    },
    {
        id: 'NC35',
        title: 'Lidiya Oslopovskih vs Tais\nPins matches. 2013',
        imgRef: video35,
    },
    {
        id: 'NC33',
        title: 'Crossfit tournament\nSubmission Grappling. 2013',
        imgRef: video33,
    },
    {
        id: 'NC29',
        title: 'Elena Vasilyeva vs Tais\nSubmission Grappling. 2013',
        imgRef: video29,
    },
    {
        id: 'NC27',
        title: 'Female Beach Wrestling\nPart 2. June, 2011',
        imgRef: video27,
    },
    {
        id: 'NC26',
        title: 'Female Beach Wrestling\nPart 2. June, 2011',
        imgRef: video26,
    },
    {
        id: 'NC24',
        title: 'Lyudmila vs Tais\nSubmission Grappling. 2011',
        imgRef: video24,
    },
    {
        id: 'NC23',
        title: 'Irina (Vlasta) vs Tais\nSubmission Grappling. 2011',
        imgRef: video23,
    },
    {
        id: 'NC19',
        title: 'Varvara Akulova vs Tais\nSubmission Grappling. 2012',
        imgRef: video19,
    },
    {
        id: 'NC16',
        title: 'Elena Vasilyeva vs Tais\nSubmission Grappling. 2011',
        imgRef: video16,
    },
    {
        id: 'NC12',
        title: 'Submission Grappling\nTournament.  April,  2010',
        imgRef: video12,
    },
    {
        id: 'NC08',
        title: 'Women\'s Beach Tournament\nSubmission Grappling.  2011',
        imgRef: video8,
    },
    {
        id: 'NC06',
        title: 'Maria Rylyova  vs Tais\nArmwrestling and Wrestling',
        imgRef: video6,
    },
    {
        id: 'NC04',
        title: 'Submission Grappling\n"Christmas Cup  2011"',
        imgRef: video4,
    },
    {
        id: 'NC03',
        title: 'Beach Wrestling\nMixed Tournament.  2010',
        imgRef: video3,
    },
    {
        id: 'NC02',
        title: 'Submission Grappling\nPart 2.  June,  2010',
        imgRef: video2,
    },
    {
        id: 'NC01',
        title: 'Submission Grappling\nPart 1.  June,  2010',
        imgRef: video1,
    },
]

export const cardsMMA: Array<CardType> = [
    {
        id: 'NC09',
        title: 'MMA tournament\n“Christmas Cup 2012”',
        imgRef: video9,
    },
    {
        id: 'NC17',
        title: 'MMA and Submission\nGrappling. February, 2012',
        imgRef: video17,
    },
    {
        id: 'NC18',
        title: 'MMA. Kara Teller vs Darya\nBalina and Tais.  May, 2012',
        imgRef: video18,
    },
    {
        id: 'NC20',
        title: 'MMA. Kara Teller vs Darya\nBalina and Tais.  May, 2012',
        imgRef: video20,
    },
    {
        id: 'NC21',
        title: 'MMA. Darya Balina vs\nOlga. July, 2012',
        imgRef: video21,
    },
]

export const cardsBoxing: Array<CardType> = [
    {
        id: 'NC13',
        title: 'V.Perfilyeva  vs  N.Ahmerova\nKickboxing. 2011',
        imgRef: video13,
    }
]

export const cardsMix: Array<CardType> = [
    {
        id: 'NC03wm',
        title: 'Beach Wrestling\nMixed Tournament. 2010',
        imgRef: video3wm,
    },
    {
        id: 'NC05',
        title: 'Villian  vs  Tais\nMixed Wrestling. 2011',
        imgRef: video5,
    },
    {
        id: 'NC07',
        title: 'Alex  vs  Tais\nExtreme fight. 2011',
        imgRef: video7,
    },
    {
        id: 'NC10',
        title: 'Mixed Wrestling\nBest Fights. Part 1. 2011',
        imgRef: video10,
    },
    {
        id: 'NC11',
        title: 'Alex  vs  Elena\nBeach Wrestling. 2011',
        imgRef: alexElena,
    },
    {
        id: 'NC14',
        title: 'Two men against one\nwoman. Part 1. 2011',
        imgRef: video14,
    },
    {
        id: 'NC15',
        title: 'Two men against one\nwoman. Part 2. 2011',
        imgRef: video15,
    },
    {
        id: 'NC22',
        title: 'Irina and Elena vs Villian\nMixed Wrestling. 2011',
        imgRef: video22,
    },
    {
        id: 'NC25',
        title: 'Mixed Wrestling\nBest Fights. Part 2. 2011',
        imgRef: video25,
    },
    {
        id: 'NC28',
        title: 'Mixed Wrestling\nBest Fights. Part 3. 2011',
        imgRef: video28,
    },
    {
        id: 'NC30',
        title: 'Mixed Wrestling\nBest Fights. Part 4. 2013',
        imgRef: video30,
    },
    {
        id: 'NC31_1',
        title: 'Mixed Wrestling\nBest Fights. Part 5.1. 2013',
        imgRef: video31_1,
    },
    {
        id: 'NC31_2',
        title: 'Mixed Wrestling\nBest Fights. Part 5.2. 2013',
        imgRef: video31_2,
    },
    {
        id: 'NC32',
        title: 'Mixed Wrestling\nArtem vs Tais. 2013',
        imgRef: video32,
    },
    {
        id: 'NC34',
        title: 'Mixed Wrestling\nArtem vs Tais. 2013',
        imgRef: video34,
    },
    {
        id: 'NC38',
        title: 'Mixed Wrestling\nAlexander vs Tais. 2014',
        imgRef: video38,
    },
    {
        id: 'NC39',
        title: 'Mixed Wrestling\nVillian vs Tais. Part 1. 2014',
        imgRef: video39,
    },
    {
        id: 'NC40',
        title: 'Mixed Wrestling\nVillian vs Tais. Part 2. 2014',
        imgRef: video40,
    },
    {
        id: 'NC45',
        title: 'Braivs vs Alyona\n11.10.2016',
        imgRef: nc45,
    },
    {
        id: 'NC49',
        title: 'Braivs vs Alyona collection\n2016-2017',
        imgRef: nc49,
    },
    {
        id: 'NC50',
        title: 'Tais vs Braivs collection\n2014-2017',
        imgRef: nc50,
    }
]